
import React from 'react';
import { ThemeConfig } from './theme';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { store, persistor } from './redux/store';
import routes, { renderRoutes } from './routes';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import { PersistGate } from 'redux-persist/lib/integration/react';
import axios from 'axios';
import JwtProvider from './components/Auth/JwtProvider';
// ----------------------------------------------------------------------

// // axios.defaults.baseURL = 'http://165.227.4.217:5001';
// axios.defaults.baseURL = 'https://staging.talent.qrata.ai';
axios.defaults.baseURL = 'https://dev-talentapi.qrata.ai';

// axios.defaults.baseURL = 'http://localhost:5015'; // new talent server

const history = createBrowserHistory();

// console.log(history, 'history of url');

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<LoadingScreen />} persistor={persistor}>
        <ThemeConfig>
          <JwtProvider>
            <Router history={history}>
              <ScrollToTop />
              {renderRoutes(routes)}
            </Router>
          </JwtProvider>
        </ThemeConfig>
      </PersistGate>
    </Provider>
  );
}

export default App;
